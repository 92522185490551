module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CoffeeBooksAndCake","short_name":"CoffeeBooksAndCake","start_url":"/","background_color":"#fff","theme_color":"#a2466c","display":"standalone","icon":"src/images/coffee.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"32e5e49ab2290aaf06c82f765a7a7578"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
