// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-authors-jsx": () => import("./../../../src/pages/authors.jsx" /* webpackChunkName: "component---src-pages-authors-jsx" */),
  "component---src-pages-books-jsx": () => import("./../../../src/pages/books.jsx" /* webpackChunkName: "component---src-pages-books-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-settings-jsx": () => import("./../../../src/pages/settings.jsx" /* webpackChunkName: "component---src-pages-settings-jsx" */),
  "component---src-pages-tags-jsx": () => import("./../../../src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */),
  "component---src-templates-authors-jsx": () => import("./../../../src/templates/Authors.jsx" /* webpackChunkName: "component---src-templates-authors-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-generic-page-jsx": () => import("./../../../src/templates/GenericPage.jsx" /* webpackChunkName: "component---src-templates-generic-page-jsx" */),
  "component---src-templates-genres-jsx": () => import("./../../../src/templates/Genres.jsx" /* webpackChunkName: "component---src-templates-genres-jsx" */),
  "component---src-templates-reading-post-jsx": () => import("./../../../src/templates/ReadingPost.jsx" /* webpackChunkName: "component---src-templates-reading-post-jsx" */),
  "component---src-templates-tags-jsx": () => import("./../../../src/templates/Tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */)
}

